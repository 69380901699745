

import {Vue} from 'vue-class-component';
import 'mdui/dist/css/mdui.css';
import {useStore} from "@/store/store";

export default class App extends Vue {

  store = useStore();

  beforeMount() {
    const url = new URL(location.href);
    const urlSearchParams = Object.fromEntries(url.searchParams)
    if (urlSearchParams.t) {
      this.store.commit('setToken', urlSearchParams.t)
      delete urlSearchParams.t
      this.$router.replace({path: url.pathname, query: urlSearchParams})
    }
  }
}
