import ky from "ky";
import {store} from '@/store/store'
import {NormalizedOptions} from "ky/distribution/types/options";

interface Res {

}

const BASE_URL = "https://loinky.com"
export  const BASE_API_URL = "https://api.loinky.com"
//export const BASE_API_URL = "http://localhost:3000"
const api = ky.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('loinky_auth_token',store.state.token || '');
            }
        ],
        // afterResponse: [
        //     (request: Request, options: NormalizedOptions, response: Response) => {
        //         console.log(response)
        //     }
        // ]
    }
});
export default {
    api: api,
    getIp: () => api.get(BASE_API_URL + "/getip").json(),
    getIpLog: () => api.get(BASE_API_URL + "/data/ip_log?orderBy=id+desc&limit=1").json(),
    getForeignIp: () => api.get('https://jsonip.com/').json(),
    geoIp: (ip:string) => api.get(BASE_API_URL + "/geoip",{searchParams:{ip}}).json(),
    loginCheck: () => api.get(BASE_API_URL + "/login/check").json(),
    devicesList: () => api.get(BASE_API_URL + "/devices/list").json(),
    searchSuggestion: (wd: string) => api.get(BASE_API_URL + "/search/baidu/suggestion", {searchParams: {wd}}).json(),
    mainList: () => api.get(BASE_API_URL + "/main/list").json(),
    bingImage: () => api.get(BASE_API_URL + "/image/bing").json(),
    fundList: () => api.get(BASE_API_URL + "/fund/list").json(),
    fundTotal: () => api.get(BASE_API_URL + "/fund/total").json(),
    fundTotalList: () => api.get(BASE_API_URL + "/fund/total/list").json(),
    fundSports: () => api.get(BASE_API_URL + "/fetch/hupu").json(),
    fundValuationList: (start:string,end:string) => api.get(BASE_API_URL + "/fund/valuation/list", {searchParams: {start,end}}).json(),
    fundValuation: (fundcode: string) => api.get(BASE_API_URL + "/fund/valuation", {searchParams: {fundcode}}).json(),
    fundValuationCal: (fundcode: string) => api.get(BASE_API_URL + "/fund/valuation/cal", {searchParams: {fundcode}}).json(),
    stockList: () => api.get(BASE_API_URL + "/fund/stock/list").json(),
    expressList: () => api.get(BASE_API_URL + "/express").json(),
    searchExpress: (code:string) => api.get(BASE_API_URL + "/search/kuaidi",{searchParams: {code,method:'query'}}).json(),
    saveList: (data:any) => api.post(BASE_API_URL + "/express",{json: data}).json(),
    getStockState: (stockcode: string) => api.get(BASE_API_URL + "/fund/stock", {searchParams: {stockcode}}).json(),
    getSignatureUrl: (searchParams: any) => api.get(BASE_API_URL + "/getSignatureUrl", {searchParams}).json(),
    getStsToken: () => api.get(BASE_API_URL + "/getStsToken", ).json(),
    addHistoryList: (data:any) => api.post(BASE_API_URL + "/data/loinky_server_search_history/create",{json: data}).json(),
    getWeather: () => api.get(BASE_API_URL + "/data/scripts_weather_history",{searchParams: {orderBy:'id desc',limit:1}}).json(),
    getHistoryList: (limit=10,where:string = '1=1') => api.get(BASE_API_URL + "/data/loinky_server_search_history", {searchParams:{orderBy:'id desc',limit,where}}).json(),
    getTextCommon: () => api.get(BASE_API_URL + "/text/common", ).json(),
    getBookmarks: () => api.get(BASE_API_URL + "/data/cache/loinky-chrome-bookmarks", {searchParams: {type: 'json'}}).json(),
    getUserInfo: () => api.get(BASE_API_URL + "/login/info",).json(),
    removeMessage: (id: string) => api.post(BASE_API_URL + "/admin/message/list/remove", {json: {id}}).json(),
    getLocationGroups: () => api.get(BASE_API_URL + "/data/location/group").json(),
    getLocationCityGroups: () => api.get(BASE_API_URL + "/data/location/group/city").json(),
    getLocation: (searchParams:{where:string,limit:number,orderBy:string}) => api.get(BASE_API_URL + "/data/location",{searchParams}).json(),
    getCalorie: (searchParams:{where:string,limit:number,orderBy:string}) => api.get(BASE_API_URL + "/data/loinky_server_food_calorie",{searchParams}).json(),
    getCalorieGroup: () => api.get(BASE_API_URL + "/data/calorie/group").json(),
    searchBooheeFood: (keyword:string) => api.get(BASE_API_URL + "/fetch/boohee/food",{searchParams:{keyword}}).json(),
    addCalorie: (data:any) => api.post(BASE_API_URL + "/data/loinky_server_food_calorie/create",{json: data}).json(),
    updateToolGroups: (data:any) => api.post(BASE_API_URL + "/data/loinky_server_kv_storage/update",{json: data}).json(),
    getToolGroups: () => api.get(BASE_API_URL + "/data/loinky_server_kv_storage",{searchParams: {orderBy:'id desc',limit:100,where:`storage_key = 'toolsGroups'`}}).json(),
    getMarkDownWebdav: () => api.get(BASE_API_URL + "/data/loinky_server_kv_storage",{searchParams: {orderBy:'id desc',limit:100,where:`storage_key = 'loinky_server_markdown_cloud_webdav'`}}).json(),
    getDanmuWebdav: () => api.get(BASE_API_URL + "/data/loinky_server_kv_storage",{searchParams: {orderBy:'id desc',limit:100,where:`storage_key = 'loinky_server_danmu_alist_webdav'`}}).json(),
    removeCalorie: (id:string) => api.post(BASE_API_URL + "/data/loinky_server_food_calorie/remove",{json: {columnName:'id',id}}).json(),
    getLolMatch: () => fetch("https://proxy.loinky.com/web201612/data/LOL_MATCH2_MATCH_HOMEPAGE_BMATCH_LIST.js").then(res=>res.json()).then(res=>res.msg.filter((i:any)=>i.GameName === '2024职业联赛')),
    ocr: (searchParams:{image:string}) => api.get(BASE_API_URL + "/search/baidu/ocr",{searchParams} ).json(),
    generateToken: (searchParams:{ua:string}) => api.get(BASE_API_URL + "/login/token/generate",{searchParams} ).json()
};
