import mdui from "mdui";
import request from "./request";
import BMF from 'browser-md5-file';
import {FileStat} from "webdav";

interface Window {
    canvasForTextWidth: HTMLCanvasElement;
}

let timer: number = -1;
const MAIN_LIST_LOCAL_STORAGE_KEY = "loinky_com_main_list"

const getCanvas = () => {
    // @ts-ignore
    const window1: Window = window
    if (!window1['canvasForTextWidth']) {
        window1['canvasForTextWidth'] = document.createElement("canvas")
    }
    return window1['canvasForTextWidth']
}
export default {
    getIp: async () => {
        const {data}: any = await request.getIp();
        const {ip, geo} = data
        const {ip: foreignIp}: any = await request.getForeignIp()
        const {data: foreignData}: any = await request.geoIp(foreignIp)
        const {geo: foreignGeo} = foreignData
        return {ip,foreignGeo,geo,foreignIp}

    },
    getTextWidth: (text: string) => {
        const canvas = getCanvas()
        const context = canvas.getContext("2d");
        if (context) {
            context.font = "16px Roboto";
            const metrics = context?.measureText(text);
            return metrics?.width;
        }
        return 800;
    },
    getUUID: () => {
        const s: any[] = [];
        const hexDigits = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ"
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
        }
        s[14] = "4"
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
        s[8] = s[13] = s[18] = s[23] = "-"
        return s.join("")
    },
    deviceType: () => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "tablet";
        } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            return "mobile";
        }
        return "desktop";
    },
    debounce: (fn: () => void, delay: number) => {
        clearTimeout(timer);
        timer = window.setTimeout(function () {
            fn();
        }, delay);
        return timer
    },
    MAIN_LIST_LOCAL_STORAGE_KEY: MAIN_LIST_LOCAL_STORAGE_KEY,
    getLinks: () => {
        const data = localStorage.getItem(MAIN_LIST_LOCAL_STORAGE_KEY)
        if (data)
            return JSON.parse(data)
        return []
    },
    groupBy: (xs: any[], key: string) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    },
    unique: (array: any[]) => {
        return [...new Set(array)];
    },
    getFileMd5: (file: File) => {
        const bmf = new BMF();
        return new Promise((resolve, reject) => {
            bmf.md5(
                file,
                (err: any, md5: string) => {
                    if (err) {
                        resolve(String(file.lastModified))
                    }
                    resolve(md5); // 97027eb624f85892c69c4bcec8ab0f11
                }
            );
        })
    },
    getTree(list: FileStat[], currentPath = '', parentFolder = '', deep = 0): any {
        const array = this.unique(list.map(i => i.filename.trim().split('/')[deep + 1])).map(i => {
            const isFolder = !(i?.endsWith('.md') || i.endsWith('.txt')|| i.endsWith('.xml'))
            const newParentFolder = `${parentFolder}/${i}`
            return ({
                etag: i,
                filename: i,
                path: newParentFolder,
                open: currentPath ? currentPath.startsWith(newParentFolder) : false,
                isFolder,
                sort: isFolder ? 1 : 0,
                children: (isFolder && deep < 5) ? this.getTree(list.filter(j => j.filename.startsWith(newParentFolder)), currentPath, newParentFolder, deep + 1) : null
            })
        })
        array.sort((a, b) => b.sort - a.sort)
        return array
    }
}
