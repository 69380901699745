import {InjectionKey} from 'vue'
import {createStore, Store, useStore as baseUseStore} from 'vuex'
import dayjs from 'dayjs'
import request from "@/utils/request";
import mdui from "mdui";
import {Socket} from "socket.io-client";
import {connect, SocketConnectStatus} from "@/utils/socket.io";
import utils from "@/utils/utils";

export interface StsToken {
    SecurityToken?: string;
    AccessKeyId?: string;
    AccessKeySecret?: string;
    Expiration?: Date;
}

interface IState {
    token: string | null,
    historyList: {}[],
    socket: Socket | null,
    messageList: any[],
    settings: {
        newTab: boolean,
        inExtension: boolean
    },
    socketConnectStatus: SocketConnectStatus,
    stsToken: StsToken,
    bookmarks: any[],
    userInfo: any,
    isInApp: boolean
}

declare global {
    interface Window {
        loinkyApp: any;
    }
}
export const key: InjectionKey<Store<IState>> = Symbol()

const STORAGE_KEY = {
    TOKEN: 'loinky_com_t',
    HISTORY: 'loinky_com_search_history',
    SETTINGS: 'loinky_com_search_settings',
    STS_TOKEN: 'loinky_com_sts_token',
}
const urlSearchParams = new URLSearchParams(window.location.search);
export const store = createStore<IState>({
    state: {
        token: urlSearchParams.get("t") || localStorage.getItem(STORAGE_KEY.TOKEN),
        historyList: JSON.parse(localStorage.getItem(STORAGE_KEY.HISTORY) || '[]'),
        socket: null,
        messageList: [],
        socketConnectStatus: SocketConnectStatus.loading,
        settings: JSON.parse(localStorage.getItem(STORAGE_KEY.SETTINGS) || '{"newTab":"","inExtension":false}'),
        stsToken: {},
        bookmarks: [],
        isInApp: !!window.loinkyApp,
        userInfo: null
    },
    getters: {},
    mutations: {
        setToken(state, newToken: string) {
            if (newToken === localStorage.getItem(STORAGE_KEY.TOKEN)) {
                return;
            }
            if (newToken) {
                localStorage.setItem(STORAGE_KEY.TOKEN, newToken)
                state.token = newToken
                request.loginCheck().then((response: any) => {
                    mdui.snackbar({
                        message: `${response.code} - ${response.message}`
                    });
                    // location.reload();
                })
            }
        },
        async addHistoryList(state, {text, type}: any) {
            if (text)
                if (!state.historyList.some(({text: string}: any) => string === text)) {
                    const time = dayjs().format('YYYY-MM-DD HH:mm:ss')
                    const newList = [{text, time, type}, ...state.historyList]
                    localStorage.setItem(STORAGE_KEY.HISTORY, JSON.stringify(newList))
                    state.historyList = newList
                    return request.addHistoryList({text, type, time})
                }
        },
        clearHistoryList(state) {
            localStorage.removeItem(STORAGE_KEY.HISTORY)
            state.historyList = []
        },
        setHistoryList(state, list) {
            if (list) {
                localStorage.setItem(STORAGE_KEY.HISTORY, JSON.stringify(list))
                state.historyList = list
            }
        },
        changeSocketConnectStatus(state, status: SocketConnectStatus) {
            state.socketConnectStatus = status
        },
        setSocket(state, socket: Socket) {
            state.socket = socket
        },
        setBookmarks(state, bookmarks: any[]) {
            state.bookmarks = bookmarks
        },
        setUserInfo(state, userInfo: any) {
            state.userInfo = userInfo
        },
        setSTSToken(state, newToken: StsToken) {
            state.stsToken = newToken
        },
        setMessageList(state, list: []) {
            state.messageList = list
        },
        setSettings(state, settings: {}) {
            const newSettings = Object.assign({}, state.settings, settings)
            state.settings = newSettings
            localStorage.setItem(STORAGE_KEY.SETTINGS, JSON.stringify(newSettings))
        },
        appendMessageList(state, newMessage: any) {
            if (!newMessage['message'])
                newMessage['message'] = newMessage['input']
            state.messageList = [newMessage, ...state.messageList]
        }
    },
    actions: {
        socketConnect({commit, state}, {oneSignalId}) {
            if (state.token && !state.socket) {
                const socket = connect(state.token, oneSignalId)
                socket.on('auth', (data: any) => {
                    if (data.msg == "connect fail!") {
                        commit('changeSocketConnectStatus', SocketConnectStatus.auth_fail)
                        socket.disconnect()
                        commit('setSocket', null)
                    } else if (data.msg == "connect success!") {
                        commit('changeSocketConnectStatus', SocketConnectStatus.success)
                        commit('setMessageList', JSON.parse(data.input))
                        socket.on('disconnect', () => {
                            commit('changeSocketConnectStatus', SocketConnectStatus.disconnected)
                            setTimeout(() => socket.connect(), 10000)
                        });
                        socket.on('msg', (data: any) => {
                            commit('appendMessageList', data)
                        });
                        commit('setSocket', socket)
                    }
                })
            }
        },
        sendMessage({commit, state}, {message, type, push}: any) {
            const mid = utils.getUUID();
            if (state.socket && state.socketConnectStatus === SocketConnectStatus.success)
                state.socket.emit('msg', {
                    input: message,
                    message,
                    mid: mid,
                    type: type,
                    push,
                    time: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                    device_name: localStorage.getItem('loinky_com_device_info') || ''
                });
        },
        getStsToken: ({commit, state}) => new Promise((resolve, reject) => {
            const tmp = localStorage.getItem(STORAGE_KEY.STS_TOKEN)
            if (tmp) {
                try {
                    const tmpJSON = JSON.parse(tmp)
                    console.log(dayjs(tmpJSON.Expiration) - dayjs())
                    if (dayjs(tmpJSON.Expiration) - dayjs() > 0) {
                        resolve(tmpJSON)
                        return
                    }
                } catch (e) {
                    console.log(e)
                }
            }
            request.getStsToken().then(({data}: any) => {
                const {Credentials} = data
                localStorage.setItem(STORAGE_KEY.STS_TOKEN, JSON.stringify(Credentials))
                resolve(Credentials)
            })
        }),
        getHistoryList({commit, state}, {limit = 20}) {
            request.getHistoryList(limit).then(({data}: any) => {
                commit('setHistoryList', data)
            })
        },
        getBookmarks({commit, state}) {
            request.getBookmarks().then(({data}: any) => {
                if (data?.data && data.data.length > 0) {
                    const allBookmarks = data.data[0]
                    commit('setBookmarks', allBookmarks.children)
                }
            })
        },
        getUserInfo({commit, state}) {
            request.getUserInfo().then(({data, code}: any) => {
                if (code === 200) {
                    commit('setUserInfo', data)
                } else if (code === 401) {
                    commit('setHistoryList', [])
                    commit('setToken', null)
                    Object.keys(localStorage).forEach(key => {
                        if (key.startsWith('loinky_com')) {
                            localStorage.removeItem(key)
                        }
                    })
                }
            })
        }
    }
})

export function useStore() {
    return baseUseStore(key)
}
