import {createRouter, createWebHistory} from 'vue-router'

const history = createWebHistory()
export default createRouter({
    history, // 路由模式
    routes: [
        {
            path: '/',
            name: 'main',
            redirect: '/layout/index'
        },
        {
            path: '/layout',
            name: 'layout',
            redirect: '/layout/index',
            component: () => import ('@/layout/layout.vue'),
            children: [
                {
                    path: 'index',
                    name: 'index',
                    component: () => import ('@/page/Index/index.vue'),
                    meta: {nameCn: '首页'}
                },
                {
                    path: 'message',
                    name: 'message',
                    component: () => import ('@/page/Message/index.vue'),
                    meta: {nameCn: '消息', auth: true}
                },
                {
                    path: 'fund',
                    name: 'fund',
                    component: () => import ('@/page/Fund/index.vue'),
                    meta: {nameCn: 'Fund', auth: true}
                },
                {
                    path: 'stock',
                    name: 'stock',
                    component: () => import ('@/page/Stock/index.vue'),
                    meta: {nameCn: 'stock', auth: true}
                },
                {
                    path: 'common',
                    name: 'common',
                    component: () => import ('@/page/Common/index.vue'),
                    meta: {nameCn: 'common', auth: true}
                },
                {
                    path: 'sports',
                    name: 'sports',
                    component: () => import ('@/page/Sports/index.vue'),
                    meta: {nameCn: 'Sports', auth: true}
                },
                {
                    path: 'express',
                    name: 'express',
                    component: () => import ('@/page/Express/index.vue'),
                    meta: {nameCn: 'Express', auth: true}
                },
                {
                    path: 'bookmarks',
                    name: 'bookmarks',
                    component: () => import ('@/page/Bookmarks/index.vue'),
                    meta: {nameCn: 'Bookmarks', auth: true}
                },
                {
                    path: 'weather',
                    name: 'weather',
                    component: () => import ('@/page/Weather/index.vue'),
                    meta: {nameCn: 'Weather', auth: true}
                },
                {
                    path: 'location',
                    name: 'location',
                    component: () => import ('@/page/Location/index.vue'),
                    meta: {nameCn: 'Location', auth: true}
                },
                {
                    path: 'calorie',
                    name: 'calorie',
                    component: () => import ('@/page/Calorie/index.vue'),
                    meta: {nameCn: 'Calorie', auth: true}
                },
                {
                    path: 'tools',
                    name: 'tools',
                    component: () => import ('@/page/Tools/index.vue'),
                    meta: {nameCn: 'Tools', auth: true}
                },
                {
                    path: 'toolsEdit',
                    name: 'toolsEdit',
                    component: () => import ('@/page/Tools/Edit/index.vue'),
                    meta: {nameCn: 'Tools Edit', auth: true}
                },
                {
                    path: 'qrcode',
                    name: 'QRCode',
                    component: () => import ('@/page/Tools/QrCode/index.vue'),
                    meta: {nameCn: 'QR Code', auth: false}
                },
                {
                    path: 'ocr',
                    name: 'ocr',
                    component: () => import ('@/page/Tools/Ocr/index.vue'),
                    meta: {nameCn: 'OCR', auth: true}
                },
                {
                    path: 'ip',
                    name: 'ip',
                    component: () => import ('@/page/Tools/IP/index.vue'),
                    meta: {nameCn: 'IP', auth: true}
                },
                {
                    path: 'md',
                    name: 'MarkDown',
                    component: () => import ('@/page/MarkDown/index.vue'),
                    meta: {nameCn: 'MarkDown', auth: true, fluid: true}
                },
                {
                    path: 'danmu',
                    name: 'Danmu',
                    component: () => import ('@/page/Danmu/index.vue'),
                    meta: {nameCn: 'Danmu', auth: true, fluid: true}
                },
                {
                    path: 'user/token',
                    name: 'token',
                    component: () => import ('@/page/User/Token/index.vue'),
                    meta: {nameCn: 'token', auth: true, }
                },
                {
                    path: '403',
                    name: '403',
                    component: () => import ('@/page/Error/403.vue'),
                    meta: {nameCn: '403'}
                },
                {
                    path: '404',
                    name: '404',
                    component: () => import ('@/page/Error/403.vue'),
                    meta: {nameCn: '404'}
                },
            ]
        }
    ]
})
