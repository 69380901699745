import {io, Socket} from 'socket.io-client';
import {BASE_API_URL} from "@/utils/request";

export enum SocketConnectStatus {
    loading = -2,
    disconnected = -3,
    success = 0,
    auth_fail = -1,
}

export function connect(token: string, oneSignalId: string, length: number = 10): Socket {
    return io(BASE_API_URL, {
        query: {token, length, oneSignalId}
    })
}
